<template>
  <div class="relative text-center">
    <figure v-if="block.image?.length > 0" class="image mx-auto w-full">
      <NuxtImg
        v-if="imageUrl"
        :src="imageUrl"
        :alt="block.image[0].alt ? block.image[0].alt : block.image[0].title"
        :class="block.roundedImage ? 'rounded-full' : ''"
        class="inline-block"
      />
    </figure>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  block: {
    type: Object,
    default: () => {},
  },
  globals: {
    type: Object,
    default: () => {},
  },
});

const imageDisplay = props.block.imageFormat;
const imageUrl =
  imageDisplay === 'original'
    ? props.block.image[0]?.url
    : props.block.image[0]?.square;
</script>
